import Layout from "./components/shared/Layout";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import {
  createBrowserRouter,
  Link,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import { SalesProvider } from "./context/SalesContext";
import SubirArchivo from "./components/SubirArchivo";
import { Button, ConfigProvider, Empty, Result } from "antd";
import { AdvisorDashboard } from "./components/AdvisorDashboard";
import DashboardFull from "./components/DashboardFull";
import { FaHome } from "react-icons/fa";
import DashboardGeneral from "./components/DashboardGeneral";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/stats/group/ventas" />,
  },
  {
    path: "/stats",
    element: <Layout />,
    children: [
      {
        path: "/stats/",
        element: <Navigate to="/stats/group/ventas" />,
      },
      {
        path: "/stats/group/:tipo",

        children: [
          {
            path: "/stats/group/:tipo",
            element: <Dashboard />,
          },
          {
            path: "/stats/group/:tipo/all",
            element: <DashboardFull />,
          },
        ],
      },
      {
        path: "/stats/general",
        element: <DashboardGeneral />,
      },
      {
        path: "/stats/upload",
        element: <SubirArchivo />,
      },
      {
        path: "/stats/user/:id",
        element: <AdvisorDashboard />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "*",
    element: (
      <Result
        status={"404"}
        title={"Error 404"}
        subTitle={"La página que intentas acceder no existe."}
        extra={
          <div className="flex items-center justify-center">
            <Link to={"/stats/group/ventas"}>
              <Button
                type="primary"
                icon={<FaHome />}
                className="flex flex-row items-center justify-center w-fit"
              >
                Volver a inicio
              </Button>
            </Link>
          </div>
        }
      />
    ),
  },
]);

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#f8aa19",
        },
      }}
    >
      <SalesProvider>
        <RouterProvider router={router} />
      </SalesProvider>
    </ConfigProvider>
  );
}

export default App;

//farenheit to celsius function
