import { TransactionChart2, TransactionIndicator } from "./TransactionChart2";
import { FC, useCallback } from "react";
import { SalesAdvisorViewType } from "../domain/SalesAdvisorData";
import { Breadcrumb, Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";

type Props = {
  tipo: SalesAdvisorViewType;
};

export const DashboardFullContent: FC<Props> = ({ tipo }) => {
  const maxValue = (() => {
    switch (tipo) {
      case "distribuidores":
        return 30000;
      case "ventas":
        return 172000;
      case "proyectos":
      default:
        return 50000;
    }
  })();

  const transactions: Array<TransactionIndicator> = (() => {
    switch (tipo) {
      case "distribuidores":
        return [
          {
            key: "monto_oc",
            color: "#ea6839",
            name: "Monto Orden de Compra",
            label: "O. Compra",
          },
          {
            key: "monto_facturado",
            color: "#f8aa19",
            name: "Monto Facturado",
            label: "Facturado",
          },
        ];
      case "proyectos":
      case "ventas":
      default:
        return [
          {
            key: "num_visitas",
            color: "#2143a5",
            name: "Num. Visitas",
            label: "Visitas",
          },
          {
            key: "monto_cotizado",
            color: "rgba(26, 42, 83, 1)",
            name: "Monto Cotizado",
            label: "Cotizado",
          },
          {
            key: "monto_oc",
            color: "#ea6839",
            name: "Monto Orden de Compra",
            label: "O. Compra",
          },
          {
            key: "monto_facturado",
            color: "#f8aa19",
            name: "Monto Facturado",
            label: "Facturado",
          },
        ];
    }
  })();

  const currentName = (() => {
    switch (tipo) {
      case "distribuidores":
        return "Distribuidores";
      case "ventas":
        return "General";
      case "proyectos":
      default:
        return "Proyectos";
    }
  })();
  const location = useLocation();
  const navigate = useNavigate();

  const onGoBack = useCallback(() => {
    navigate(location.pathname.replaceAll("/all", ""));
  }, [location, navigate]);

  const title = (() => {
    switch (tipo) {
      case "distribuidores":
        return "Valor de venta de distribuidores";
      case "ventas":
        return "Valor de venta general";
      case "proyectos":
      default:
        return "Valor de venta de proyectos";
    }
  })();

  return (
    <div className="px-2 py-2 w-full h-full flex flex-col">
      <div className="px-2 flex flex-row flex-wrap items-center gap-2">
        <Button
          icon={<FaChevronLeft />}
          type="text"
          onClick={onGoBack}
          className="!text-primary"
        />
        <Breadcrumb
          items={[
            {
              title: currentName,
              onClick: onGoBack,
              className: "text-primary cursor-pointer",
            },
            {
              title: "Todos los indicadores",
            },
          ]}
        />
      </div>
      <div className="lg:h-full flex flex-col px-4 w-full bg-white rounded-xl lg:overflow-hidden shadow gap-2 relative">
        <div className="flex flex-row flex-wrap w-full items-center justify-between mt-3">
          <span className="text-lg font-header font-bold ">{title}</span>
        </div>
        <div className="w-full lg:flex-1 p-2 lg:pr-1">
          <TransactionChart2 transactions={transactions} max={maxValue} />
        </div>
      </div>
    </div>
  );
};
