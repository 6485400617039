import { useContext, useEffect, useRef } from "react";
import { SalesContext } from "../context/SalesContext";
import { Empty, Spin, Typography } from "antd";
import { SalesDataService } from "../data/SalesDataService";
import { DashboardContent } from "./DashboardContent";
import { isEmpty, isNil } from "lodash";
import { useParams } from "react-router-dom";
import { SalesAdvisorViewType } from "../domain/SalesAdvisorData";
import { SALES_ADVISOR_VIEW_TYPES } from "../lib/constants/advisor";

export default function Dashboard() {
  const { setData, data, dataState, setDataState } = useContext(SalesContext);
  const viewType = useRef<SalesAdvisorViewType>();
  const { tipo } = useParams();

  useEffect(() => {
    if (
      isNil(tipo) ||
      isEmpty(tipo) ||
      !SALES_ADVISOR_VIEW_TYPES.includes(tipo as SalesAdvisorViewType)
    ) {
      setDataState({ loading: false, error: "Tipo de usuario no válido" });
      return;
    }
    viewType.current = tipo as SalesAdvisorViewType;
    setDataState({ loading: true, error: null });
    SalesDataService.getSalesDataByViewType(viewType.current)
      .then((res) => {
        setData(res);
        setDataState({ loading: false, error: null });
      })
      .catch((err) => {
        setDataState({ loading: false, error: err.message });
      });
  }, [tipo]);

  return (
    <div className="w-full h-full overflow-x-hidden overflow-y-auto lg:overflow-y-hidden">
      {dataState?.loading ? (
        <div className="w-full h-full flex flex-col justify-center items-center gap-4">
          <Spin
            size="large"
            fullscreen
            tip={
              <Typography.Text type="secondary">
                Obteniendo estadísticas...
              </Typography.Text>
            }
          />
        </div>
      ) : !isEmpty(data?.data) ? (
        <DashboardContent tipo={viewType.current!} />
      ) : (
        <Empty />
      )}
    </div>
  );
}
