import {
  FC,
  Fragment,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { SalesContext } from "../context/SalesContext";
import {
  flattenDeep,
  groupBy,
  isEmpty,
  isNil,
  orderBy,
  sortBy,
  toString,
  uniq,
} from "lodash";
import { useNavigate } from "react-router-dom";
import { parseCurrency, parseCurrency2 } from "../lib/utils/currency";
import {
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Bar,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  LegendProps,
  Cell,
  ReferenceLine,
} from "recharts";
import { formatChartNames } from "../lib/utils/name";
import { SingleSalesAdvisorData } from "../domain/SalesAdvisorData";
import { TransactionIndicator } from "./TransactionChart2";
import { SALES_ADVISOR_USER_TYPES } from "../lib/constants/advisor";
import {
  DISTRIBUTORS_BILLING_TARGET,
  PROJECTS_BILLING_TARGET,
  SALES_BILLING_TARGET,
} from "../lib/constants/sales";

type Props = {
  transactions: Array<TransactionIndicator>;
};

type BarSelectionProps = {
  hover: keyof SingleSalesAdvisorData | null;
} & {
  [key in keyof Partial<SingleSalesAdvisorData>]: boolean | null;
};

const SALES_TICKS = [
  [0, 0.3 * SALES_BILLING_TARGET],
  [0.3 * SALES_BILLING_TARGET, 0.6 * SALES_BILLING_TARGET],
  [0.6 * SALES_BILLING_TARGET, SALES_BILLING_TARGET],
];

const PROJECTS_TICKS = [
  [0, 0.3 * PROJECTS_BILLING_TARGET],
  [0.3 * PROJECTS_BILLING_TARGET, 0.6 * PROJECTS_BILLING_TARGET],
  [0.6 * PROJECTS_BILLING_TARGET, PROJECTS_BILLING_TARGET],
];

const Y_AXIS_TICKS = uniq(
  sortBy(flattenDeep([...SALES_TICKS, ...PROJECTS_TICKS]))
);

export const TransactionChartGeneral: FC<Props> = ({ transactions }) => {
  const { generalData } = useContext(SalesContext);
  const data: SingleSalesAdvisorData[] = useMemo(() => {
    const group = groupBy(generalData!.data!, (it) => it.tipo);
    let result: SingleSalesAdvisorData[] = [];
    for (const key in group) {
      result = [...result, ...group[key]];
    }
    return result;
  }, [generalData]);

  const getColor = (data: SingleSalesAdvisorData) => {
    if (data.nombres.toLowerCase() === "total") return "#112233";
    const f = data.monto_facturado;
    const ticks = (function () {
      switch (data.tipo) {
        case "asesor":
        case "distribuidor":
        case "servicios":
          return SALES_TICKS;
        case "ingeniero":
          return PROJECTS_TICKS;
      }
    })();
    let color = "#4CAF50";
    ticks.forEach((tick, index) => {
      if (f >= tick[0] && f <= tick[1])
        switch (index) {
          case 0:
            color = "#D32F2F";
            break;
          case 1:
            color = "#FBC02D";
            break;
          default:
            color = "#4CAF50";
            break;
        }
    });
    return color;
  };

  const navigate = useNavigate();

  const defaultBarProps = (() => {
    const it2 = transactions.reduce(
      (prev, current) => ({
        ...prev,
        [current.key]: false,
      }),
      {}
    );
    let result = {
      hover: null,
    };
    result = {
      ...result,
      ...it2,
    };
    return result;
  })();

  const [barProps, setBarProps] = useState<BarSelectionProps>(defaultBarProps);

  const handleLegendMouseEnter: LegendProps["onMouseOver"] = (e) => {
    const dataKey = e.dataKey as keyof SingleSalesAdvisorData | undefined;
    if (!isNil(dataKey)) {
      setBarProps({ ...barProps, hover: dataKey });
    }
  };

  const handleLegendMouseLeave: LegendProps["onMouseOut"] = (_) => {
    setBarProps({ ...barProps, hover: null });
  };

  const selectBar: LegendProps["onClick"] = (e) => {
    const dataKey = e.dataKey as keyof SingleSalesAdvisorData | undefined;
    setBarProps({
      ...barProps,
      [dataKey!]: !barProps[dataKey!],
      hover: null,
    });
  };

  const onBarClicked = useCallback(
    (data: SingleSalesAdvisorData) => {
      const id = data.id;
      if (!isNil(id)) {
        navigate(`/stats/user/${encodeURI(toString(id))}`);
      }
    },
    [navigate]
  );

  const renderIndicator =
    (key: keyof Partial<SingleSalesAdvisorData>, color: string) =>
    (tickProps: any) => {
      const { x, y, payload } = tickProps;
      const { index } = payload;
      const value = data[index][key];
      if (!isNil(value)) {
        return (
          <text
            x={x + 10}
            y={y}
            fontFamily="Poppins"
            className="text-xs"
          >{`${parseCurrency2(value)}`}</text>
        );
      }
      return <Fragment />;
    };

  const renderVisitas = (color: string) => (tickProps: any) => {
    const { x, y, payload } = tickProps;
    const { index } = payload;
    const value = data[index]["num_visitas"];
    if (!isNil(value)) {
      return (
        <text
          x={x + 10}
          y={y + 4}
          fill={value < 30 ? "red" : color}
          fontFamily="Poppins"
          fontSize={20}
          fontWeight={700}
        >{`${value}`}</text>
      );
    }
    return <Fragment />;
  };
  const renderTipoTick = (tickProps: any) => {
    const { x, y, index } = tickProps;
    const pathX = Math.floor(x);
    const current = data[index];
    const next = data[index + 1];
    const changes = !!current && !!next && current.tipo != next.tipo;
    if (changes) {
      return <path d={`M${x},${y - 4}v${-35}`} stroke="red" />;
    }
    return <Fragment />;
  };

  const toRenderBars = useMemo(() => {
    return transactions.filter((it) => barProps[it.key] === false);
  }, [barProps, transactions]);

  return (
    <ResponsiveContainer width={"100%"} height={"100%"}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3 0 0" vertical={false} />
        <XAxis
          dataKey="name"
          tickFormatter={formatChartNames}
          className={"text-xs font-header font-semibold text-black"}
          angle={-45}
          textAnchor="end"
          interval={0}
          height={85}
        />
        <XAxis xAxisId="1" dataKey="tipo" allowDuplicatedCategory />
        <YAxis
          domain={[0, 172000]}
          className={
            "text-xs md:text-sm lg:text-sm font-header font-semibold text-black"
          }
          width={90}
          tickFormatter={parseCurrency2}
          ticks={Y_AXIS_TICKS}
        />
        <ReferenceLine y={SALES_BILLING_TARGET} label={"Ventas"} isFront />
        <ReferenceLine
          y={PROJECTS_BILLING_TARGET}
          label={"Proyectos"}
          isFront
        />
        <ReferenceLine
          y={DISTRIBUTORS_BILLING_TARGET}
          label={"Distribuidores"}
          isFront
        />
        <Tooltip
          contentStyle={{ fontWeight: 500 }}
          itemStyle={{ fontWeight: 500 }}
          cursor={{
            strokeWidth: 2,
            fill: "rgba(50,50,50,0.1)",
          }}
          formatter={parseCurrency}
          labelClassName="font-header outline-none border-none"
          wrapperClassName="font-header outline-none border-none"
          content={({ payload, label }) => {
            if (isEmpty(payload)) {
              return null;
            }
            const data = payload![0].payload;
            return (
              <div className="bg-white border border-gray-300 shadow-lg rounded-md p-2 gap-2">
                <div className="font-header font-semibold text-black text-sm">
                  {label}
                </div>
                {transactions.map((label, index) => (
                  <div
                    key={`tooltip-${index}`}
                    className="flex flex-col items-start justify-between"
                  >
                    <div
                      className="font-header font-semibold text-black text-xs flex flex-row gap-1 items-center underline"
                      style={{
                        textDecorationColor: label.color,
                      }}
                    >
                      <div
                        style={{ backgroundColor: label.color }}
                        className="w-2 aspect-square"
                      />
                      {label.name}
                    </div>
                    <div className="font-header font-normal text-black text-xs">
                      {label.key.includes("num")
                        ? data[label.key]
                        : parseCurrency(data[label.key])}
                    </div>
                  </div>
                ))}
              </div>
            );
          }}
        />
        <Legend
          onClick={selectBar}
          onMouseOver={handleLegendMouseEnter}
          onMouseOut={handleLegendMouseLeave}
        />

        {transactions
          .filter((it) => it.key !== "num_visitas")
          .map((label, index) => (
            <Bar
              radius={[5, 5, 0, 0]}
              dataKey={label.key}
              fill={label.color}
              name={label.name}
              onClick={onBarClicked}
              key={`bar-${index}`}
              hide={barProps[label.key] === true}
              fillOpacity={Number(
                barProps.hover === label.key || !barProps.hover ? 1 : 0.6
              )}
            >
              {toRenderBars.length === 1 && (
                <LabelList
                  dataKey={label.key}
                  position="top"
                  angle={0}
                  className={
                    "invisible md:visible text-xs md:text-sm font-semibold font-header"
                  }
                  formatter={parseCurrency2}
                />
              )}
              {data.map((it, idx) => {
                return <Cell fill={getColor(it)} key={`cell-${idx}`} />;
              })}
            </Bar>
          ))}

        {!!toRenderBars &&
          toRenderBars.length > 1 &&
          toRenderBars.map((it) => (
            <XAxis
              key={`xaxis-${it.key}`}
              dataKey="name"
              axisLine={false}
              tickLine={false}
              interval={0}
              tick={
                it.key === "num_visitas"
                  ? renderVisitas(it.color)
                  : renderIndicator(it.key, it.color)
              }
              scale="band"
              xAxisId={it.key}
              label={(props: any) => {
                const {
                  viewBox: { y },
                } = props;
                return (
                  <text
                    x={14}
                    y={y + 10}
                    fontFamily="Poppins"
                    fillOpacity={0.6}
                    fontSize={14}
                    fontWeight={500}
                  >
                    {it.label}
                  </text>
                );
              }}
            />
          ))}
      </BarChart>
    </ResponsiveContainer>
  );
};
